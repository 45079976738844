<template>
  <form @submit.prevent="loginJWT" class="mt-3 pt-3">
    <vs-input
      v-if="!send_code"
        v-validate="'required|min:3'"
        data-vv-validate-on="blur"
        name="phone"
        icon-no-border
        icon="icon icon-phone-call"
        icon-pack="feather"
        label-placeholder="Phone Number"
        v-model="phone"
        class="w-full text-left"/>
    <vs-input
      v-else
      data-vv-validate-on="blur"
      type="password"
      name="code"
      icon-no-border
      icon="icon icon-lock"
      icon-pack="feather"
      label-placeholder="Code"
      v-model="code"
      class="w-full mt-6 text-left" />

    <span class="text-danger text-sm">{{ errors.first('phone') }}</span>


    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <div class="flex flex-wrap justify-between mb-3 mt-3">
      <vs-button :disabled="!validateForm" @click="loginJWT">Login</vs-button>
    </div>
  </form>
</template>

<script>

export default {
  data() {
    return {
      phone: '',
      code: '',
      send_code: null
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.phone !== '';
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    async loginJWT() {

      if (!this.checkLogin()) return
      // Loading


      let phone = this.phone.slice(1,)



      if(!this.send_code){
        this.$http.post('/auth/verify-user/',{
          phone,
          country_code: "98"
        })
          .then(() => {
            this.send_code = true
            this.$vs.loading.close()
          })
      }else{
        phone = `98${ phone }`
        const code = this.code

        this.$store.dispatch('auth/loginJWT', {phone, code})
        .then(() => {
          // this.$store.dispatch('user/fetchUser')
          this.$http.get('/users/profile')
            .then((response) => {
              localStorage.setItem('userInfo', JSON.stringify(response.data))
            })
            .catch(() => {})

          this.$vs.loading.close()
        })
        .catch(() => this.$vs.loading.close())
      }

    }
  }
}

</script>

